import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import blueGrey from '@material-ui/core/colors/blueGrey';

// if we declare custom colors, we need to add them to the typings
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
  }

  interface PaletteOptions {
  }
}

export default createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: indigo['500'],
      contrastText: '#fff',
    },
    secondary: {
      main: blueGrey['900'],
      contrastText: grey['50'],
    },
  },
});
